import { Gender } from "@/@types/enums";
import { Ad } from "@/@types/ad";

export enum InsuranceStatus {
   Applied = 1,
   Submitted = 2,
   Approved = 3,
   Denied = 4,
   PuppyInformationCollected = 5,
   PuppyInformationSubmitted = 6,
}

export interface Insurance {
  id: number;
  status: InsuranceStatus;
  mom_breed: string;
  mom_name: string;
  mom_birth_date: string;
  mom_chip_number: string;
  puppies_birth_date: string;
  puppies_breed: string;
  puppies_males: number;
  puppies_females: number;
  puppies_chip_numbers: InsurancePuppy[];
  ads?: Ad[];
}

export interface InsurancePuppy {
  chip_id: string;
  skk_registration_number: string;
  gender: Gender;
  name: string;
  has_buyer: boolean;
  buyer_name: string;
  buyer_phone_number: string;
  deceased: boolean;
}
