<template>
  <span class="loader mx-auto" :class="{ large }" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss">
.loader {
  width: 36px;
  height: 36px;
  display: block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: theme('colors.gray.500');
  position: absolute;
  left: 0;
  top: 0;
  animation: loader-animation 2s linear infinite;
}
.loader::after {
  animation-delay: -1s;
}

.loader.large, .loader.large::after, .loader.large::before {
  width: 62px;
  height: 62px;
}

@keyframes loader-animation {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}


.loader.white::before, .loader.white::after {
  background: theme('colors.white');
}
</style>
